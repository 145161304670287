import { DateTime } from 'luxon';
import i18n from 'i18next';
import { Sort } from '@utils/types';
import { ListEntity } from '@shared/components/list-view-v2/types';
import { EntityId } from '@reduxjs/toolkit';
import {
  DocumentContentType,
  MAX_FILES_BULK_UPLOAD_SIZE_IN_B,
  MAX_NUMBER_OF_FILES,
  NUMBER_OF_FILES_TO_UPLOAD_IF_SIZE_OF_SINGLE_FILE_EXCEEDED_20GB,
  SortDirection
} from '@helpers';
import { Dispatch, SetStateAction } from 'react';
import { ENVIRONMENT, PLATFORM } from '@aws';
import { CustomSelectOption } from '@shared/components/inputs/Inputs';
import { FileItem } from '@shared/components/file-viewer';
import { supportedImages } from '@utils/supported-media-types';
import {
  File,
  FetchFilesResponse,
  EDITOR,
  OWNER,
  FileAccessStatus,
  UploadFilesResponse,
  UploadFilesMap,
  CreateDirectoryResponse,
  CONTRIBUTOR,
  UploadFileCompleteRequestItem,
  UploadFileRequestItem
} from './types';
import { uploadFileWithParts } from './upload/upload';

export const isDirectory = (type: string) => {
  return type === DocumentContentType.DIRECTORY;
};

// TODO refactor sorting
export const filesCompareFn = ({ key, direction }: Sort) => (
  a: ListEntity | FileItem,
  b: ListEntity | FileItem
) => {
  const fileA = a as File;
  const fileB = b as File;

  const aValue = fileA[key]?.toString() || '';
  const bValue = fileB[key]?.toString() || '';

  const contentTypeOrder = Object.values(DocumentContentType);

  let result: number;

  if (key === 'content_type') {
    result =
      contentTypeOrder.indexOf(fileA.content_type) - contentTypeOrder.indexOf(fileB.content_type);
  } else if (!aValue && !bValue) {
    return 0;
  } else if (aValue && !bValue) {
    return -1;
  } else if (!aValue && bValue) {
    return 1;
  } else {
    result = aValue.localeCompare(bValue);
  }

  return direction === SortDirection.ASC ? result : result * -1;
};

export const getSearchedFiles = (files: File[], searchTerm: string): File[] => {
  if (!searchTerm) return files;

  return files.filter((file) => {
    const fileSearchString = `${file.name}${file.description}`.toLocaleLowerCase().trim();
    return fileSearchString.includes(searchTerm.toLocaleLowerCase().trim());
  });
};

const getFormattedFileDate = (): string => {
  return DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss', {
    locale: i18n.language
  });
};

export const convertBytesToMegabytes = (bytes: number): string => {
  return parseFloat(String(Number(bytes) / 1048576)).toFixed(2);
};

const getThumbnail = (file) => {
  if (file?.thumbnails?.[0]) {
    return `${PLATFORM[ENVIRONMENT].file_content_url}/${file.thumbnails[0]}`;
  }
  if (supportedImages.includes(file.content_type) || file.content_type.includes('heic')) {
    return `${PLATFORM[ENVIRONMENT].file_thumbnail_url}/${file.file_id}_150`;
  }
  return null;
};

export const updateWithAdditionalInfo = (file: File): File => {
  return {
    ...file,
    url: `${PLATFORM[ENVIRONMENT].file_content_url}/${file.file_id}`,
    thumbnail_url: getThumbnail(file),
    last_updated: file.last_updated ? file.last_updated : getFormattedFileDate()
  };
};
export const updateSharedFilesWithAdditionalInfo = (file: File): File => {
  return {
    ...file,
    url: `${PLATFORM[ENVIRONMENT].file_content_url}/${file.file_id}`,
    thumbnail_url: getThumbnail(file),
    last_updated: file.last_updated ? file.last_updated : getFormattedFileDate(),
    isShared: true
  };
};

export const isFileEditable = (
  listEntity: ListEntity | { result: string; target_email: string } | undefined
) => {
  if (!listEntity) return false;
  if (!('access_status' in listEntity)) return false;
  return [OWNER, CONTRIBUTOR, EDITOR].includes(listEntity.access_status as FileAccessStatus);
};

export const getFilesByURL = (url: string) => {
  return fetch(url, {
    method: 'GET'
  })
    .then((res) => res.blob())
    .then((blob) => blob.text())
    .then((text) => JSON.parse(text) as File[]);
};

export const transformToFiles = (response: FetchFilesResponse): File[] =>
  response.files?.map(updateWithAdditionalInfo) || [];

export const selectDirectoriesFromApiResult = (
  result: { data?: File[] },
  excludeIds?: EntityId[]
) => ({
  ...result,
  data:
    result.data?.filter(
      ({ file_id, content_type }) => isDirectory(content_type) && !excludeIds?.includes(file_id)
    ) ?? []
});

export const selectFilesFromApiResult = (result: { data?: File[] }) => ({
  ...result,
  data: result.data?.filter(({ content_type }) => !isDirectory(content_type)) ?? []
});

export const completeUpload = (
  completeUploadResponse: CreateDirectoryResponse,
  setIsSuccess: Dispatch<SetStateAction<boolean>>,
  callback: () => void
) => {
  if (completeUploadResponse.message === 'Success') {
    setIsSuccess(true);
    callback();
  } else {
    callback();
  }
};

export const uploadFilesParts = async (
  filesUploadResponse: UploadFilesResponse,
  filesWithData: UploadFilesMap,
  setUploadedFilesNumber: Dispatch<SetStateAction<number>>,
  setUploadedFilesSize: Dispatch<SetStateAction<number>>
) => {
  const cachedPayload: UploadFileCompleteRequestItem[] = [];

  console.log('filesWithData', filesWithData);

  // eslint-disable-next-line no-restricted-syntax
  for (const file of filesUploadResponse.payload) {
    // eslint-disable-next-line no-await-in-loop
    const responseFile = await uploadFileWithParts(
      file,
      filesWithData,
      setUploadedFilesNumber,
      setUploadedFilesSize
    );

    cachedPayload.push(responseFile);
  }
  return cachedPayload;
};

export const getDirectoriesWithStatusEditor = (directories: File[]) =>
  directories.filter(({ access_status }) => access_status === EDITOR);

export const getDirectoriesOptions = (directories: File[]): CustomSelectOption[] => {
  return directories.map(
    (dir) =>
      ({
        value: dir.file_id,
        label: dir.name
      } as CustomSelectOption)
  );
};

export const getNumberOfFilesToUpload = ({
  files,
  filesWithData
}: {
  files: UploadFileRequestItem[];
  filesWithData: UploadFilesMap;
}) => {
  let filesBulkSize = 0;
  let indexOfTheLastFileInBulk = 0;
  const amountOfFilesToUpload =
    files.length > MAX_NUMBER_OF_FILES ? MAX_NUMBER_OF_FILES : files.length;
  for (let fileIndex = 0; fileIndex < amountOfFilesToUpload; ) {
    const possibleFilesBulkSize = filesBulkSize + filesWithData[files[fileIndex].file_id].size;

    if (possibleFilesBulkSize < MAX_FILES_BULK_UPLOAD_SIZE_IN_B) {
      filesBulkSize = possibleFilesBulkSize;
      indexOfTheLastFileInBulk += 1;
      fileIndex += 1;
    } else {
      fileIndex = amountOfFilesToUpload;
    }
  }

  const allowedFilesLength =
    indexOfTheLastFileInBulk || NUMBER_OF_FILES_TO_UPLOAD_IF_SIZE_OF_SINGLE_FILE_EXCEEDED_20GB;
  return allowedFilesLength;
};
